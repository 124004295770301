import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import { Link } from "gatsby"
import ImagenPresentacion1 from '../components/imageComponents/imagenPresentacion1'
import ImagenPresentacion2 from '../components/imageComponents/imagenPresentacion2'
import ImagenDorados from '../components/imageComponents/imageindexDorado'
import SlideShow from '../components/imageComponents/slideShow'
import Estilos from '../components/mainContent/container.module.css'

const IndexPage = () => (
  <Layout>
    <SEO title='MÁQUINAS DE TERMOIMPRESIÓN - HOT FOIL STAMPING' />
    <Header siteTitle='MÁQUINAS DE TERMOIMPRESIÓN - HOT FOIL STAMPING' />
    <div className={Estilos.slideshow}>
      <div className='row justify-content-md-center' style={{ "marginTop": "1rem" }}>
        <SlideShow />
      </div>

      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-8 conmargen'>
          <div className="conaire">
            <p>Proporcionamos, además, todos los consumibles necesarios como
            <Link className="maquinas" to='/Grabados/'> &nbsp;grabados, </Link>
              <Link className="maquinas" to='/Peliculas/'> &nbsp;películas, </Link> &nbsp;etc.
          </p>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <ImagenDorados />
            </div>
            <div className='col-12 col-md-4'>
              <ImagenPresentacion1 />
            </div>
            <div className='col-12 col-md-4'>
              <ImagenPresentacion2 />
            </div>
          </div>
        </div>
      </div>


    </div>
  </Layout>
)

export default IndexPage
