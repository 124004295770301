import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const DoradoWrap  = styled.div`
  margin: auto 0;
  flex: 0 1 250px;

  @media (max-width: 768px) {
    flex: 0 1 125px;
    display:none;
  }

}
`
const ImagenDorados = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "home_img1" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 300, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          },
          fixed(width: 300, height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    
      <Img fluid={data.file.childImageSharp.fluid} alt="Dorados" />
    
  )
}

export default ImagenDorados
