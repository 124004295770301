import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"


const ImagenPresentacion1 = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "presentacion1" }, extension: { eq: "jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          },
          fixed(width: 300, height:300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return ( 
      <Img fluid={data.file.childImageSharp.fluid} alt="ImagenPresentacion1" />
  )
}

export default ImagenPresentacion1
